import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/hello-github-pages",
  "date": "2018-03-11T17:12:33.962Z",
  "title": "Moved site to GitHub Pages"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`GitHub Pages Update`}</h1>
    <p>{`I have completed my move from an old hosting provider to use GitHub Pages for my website. The primary reason was to eliminate paying
for a host for a website that had static content. It also allowed me to update the styling on the site and tinker with
newer frontend technologies.`}</p>
    <br />
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Where are my older blog posts?</AlertTitle>
  I did not migrate old blog posts to markdown files since the content mostly consisted of site update information and did not
  provide much value.
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      